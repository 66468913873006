import "./assets/css/tailwind.css";
import "./assets/css/core.css";
import { I18nextProvider } from "react-i18next";
import i18n from "../src/i18n/i18n";
import { LanguageProvider } from "../src/context/LanguageContext";
import FooterComponent from "./components/footer/footer";

export default function Root(props) {
  return (
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        <FooterComponent />
      </I18nextProvider>
    </LanguageProvider>
  );
}
