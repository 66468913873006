import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        headings: {
          about: "Nós",
          workWithUs: "TRABALHAR COM A GENTE",
          social: "redes sociais",
        },
        options: {
          about: {
            faqs: "Perguntas frequentes",
            myVoucher: "Minha Apólice",
            refunds: "Reembolsos",
            ccgg: "Condições Gerais",
            emergencies: "Emergências",
          },
          workWithUs: {
            enterprises: "Agências",
            affiliates: "Afiliados",
          },
        },
        switchLanguage: {
          es: "Espanhol",
          pt: "Português",
        },
        cookies: "Politica de Cookies",
        privacy: "Politica de Privacidade",
        copyright: "Copyright © 2024 ASSIST 365. Todos os direitos reservados.",
        coverInfo:
          "Assist 365 é a cobertura de assistência em viagem preferida por milhares de viajantes da Europa, América Latina, Oceania, Ásia e América do Norte.",
        disclaimer:
          "Os serviços de assistência da Assist 365 têm limitações exclusivas dependendo do tipo de produto adquirido. As limitações e exclusões de uso ou legal são regidas pelas condições gerais que são informadas na compra de cada produto Assist 365 e estão disponíveis através do site em 'condições gerais'. Também podem ser solicitados pelo e-mail: falecom@assist-365.com. Atenção: O seguro viagem não é seguro saúde. Leia atentamente as condições contratuais, observando seus direitos e obrigações, bem como o limite do capital segurado contratado para cada cobertura. Importante: o limite de idade para planos correspondentes a viagens de até 89 dias é de 85 anos. Dos 75 aos 85 anos há um aumento de 50% na tarifa para todos os destinos. O limite de idade para viagens de longa duração igual ou superior a 90 dias é de 64 anos e para planos multiviagens é de 69 anos.",
      },
    },
    es: {
      translations: {
        headings: {
          about: "sobre nosotros",
          workWithUs: "trabaja con nosotros",
          social: "redes sociales",
        },
        options: {
          about: {
            faqs: "Preguntas frecuentes",
            myVoucher: "Mi voucher",
            refunds: "Reintegros",
            ccgg: "Condiciones generales",
            emergencies: "Emergencias",
          },
          workWithUs: {
            enterprises: "Empresas",
            affiliates: "Afiliados",
          },
        },
        switchLanguage: {
          es: "Español",
          pt: "Portugués",
        },
        cookies: "Política de cookies",
        privacy: "Política de privacidad",
        copyright:
          "Copyright © 2024 ASSIST 365. Todos los derechos reservados.",
        coverInfo:
          "Assist 365 es la cobertura de asistencia al viajero preferida por miles de viajeros de Europa, Latinoamérica, Oceanía, Asia y Norteamérica.",
        disclaimer:
          "LOS SERVICIOS ASSIST 365 TIENEN LIMITACIONES EXCLUSIVAS SEGÚN EL TIPO DE PRODUCTO ADQUIRIDO. APLICAN LAS LIMITACIONES Y EXCLUSIONES DE USO HABITUAL Y/O LEGAL EN EL PAÍS EN QUE SE EMITA EL PRODUCTO ASSIST 365. A PARTIR DE LOS 75 AÑOS INCLUSIVE SE APLICA UN INCREMENTO DEL 50% SOBRE LAS TARIFAS Y A PARTIR DE LOS 75 AÑOS INCLUSIVE SE APLICA UN INCREMENTO DEL 100%. LOS SERVICIOS Y PRODUCTOS SE RIGEN POR CONDICIONES GENERALES QUE SE INFORMAN CON LA COMPRA DE CADA PRODUCTO ASSIST 365 Y SE HALLAN A SU DISPOSICIÓN INGRESANDO EN EL PORTAL WEB, PUEDEN SER SOLICITADAS EN FORMA TELEFÓNICA O POR E-MAIL. LAS ENFERMEDADES PREEXISTENTES TIENEN EXCLUSIONES Y LIMITACIONES. ASSIST 365 NO ES UNA EMPRESA DE SEGUROS, SINO QUE ACTÚA EN CALIDAD DE TOMADORA EN BENEFICIO DE SUS CLIENTES.",
      },
    },
    "es-AR": {
      translations: {
        headings: {
          about: "sobre nosotros",
          workWithUs: "trabaja con nosotros",
          social: "redes sociales",
        },
        options: {
          about: {
            faqs: "Preguntas frecuentes",
            myVoucher: "Mi voucher",
            refunds: "Reintegros",
            ccgg: "Condiciones generales",
            emergencies: "Emergencias",
          },
          switchLanguage: {
            es: "Español",
            pt: "Portugués",
          },
          workWithUs: {
            enterprises: "Empresas",
            affiliates: "Afiliados",
          },
        },
        cookies: "Política de cookies",
        privacy: "Política de privacidad",
        copyright:
          "Copyright © 2024 ASSIST 365. Todos los derechos reservados.",
        coverInfo:
          "Assist 365 es la cobertura de asistencia al viajero preferida por miles de viajeros de Europa, Latinoamérica, Oceanía, Asia y Norteamérica.",
        disclaimer:
          "LOS SERVICIOS ASSIST 365 TIENEN LIMITACIONES EXCLUSIVAS SEGÚN EL TIPO DE PRODUCTO ADQUIRIDO. APLICAN LAS LIMITACIONES Y EXCLUSIONES DE USO HABITUAL Y/O LEGAL EN EL PAÍS EN QUE SE EMITA EL PRODUCTO ASSIST 365. A PARTIR DE LOS 75 AÑOS INCLUSIVE SE APLICA UN INCREMENTO DEL 50% SOBRE LAS TARIFAS Y A PARTIR DE LOS 75 AÑOS INCLUSIVE SE APLICA UN INCREMENTO DEL 100%. LOS SERVICIOS Y PRODUCTOS SE RIGEN POR CONDICIONES GENERALES QUE SE INFORMAN CON LA COMPRA DE CADA PRODUCTO ASSIST 365 Y SE HALLAN A SU DISPOSICIÓN INGRESANDO EN EL PORTAL WEB, PUEDEN SER SOLICITADAS EN FORMA TELEFÓNICA O POR E-MAIL. LAS ENFERMEDADES PREEXISTENTES TIENEN EXCLUSIONES Y LIMITACIONES. ASSIST 365 NO ES UNA EMPRESA DE SEGUROS, SINO QUE ACTÚA EN CALIDAD DE TOMADORA EN BENEFICIO DE SUS CLIENTES.",
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
