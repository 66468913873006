// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

.footer-container {
  background: radial-gradient(
    393.95% 194.46% at -11.83% 213.72%,
    #59d3c2 0%,
    #0371e8 100%
  );
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/core.css"],"names":[],"mappings":"AAAA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB;EACE;;;;GAIC;AACH","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n.footer-container {\n  background: radial-gradient(\n    393.95% 194.46% at -11.83% 213.72%,\n    #59d3c2 0%,\n    #0371e8 100%\n  );\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
